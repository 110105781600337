import React from "react";
import "./style.scss";
import LocksReplacementImage1 from "../../../../assets/images/replace-1.jpeg";
import LocksReplacementImage2 from "../../../../assets/images/replace-2.jpeg";
import LocksInstallingImage2 from "../../../../assets/images/replace-3.jpeg";
import LockRepairingEntranceImage from "../../../../assets/images/services/door-panel.png";
import CyliderImage1 from "../../../../assets/images/image-2.jpeg";
import OverlaysImage1 from "../../../../assets/images/image-3.jpeg";
import SmartLock1 from "../../../../assets/images/services/smart-lock1.jpeg";
import Image1 from "../../../../assets/images/replace-4.jpeg";
import { Button, Modal, Box, TextField, Typography } from "@material-ui/core";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
};
const btn_styled = {
  position: "fixed",
  top: "90%",
  left: "90%",
  background: "rgba(101, 66, 122, 0.9)",
  color: "white"
}

const LocksReplacementEntrance = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Замена замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Замена замков двери</h2>
        <div className="square-container ">
          <img
            className="door-opening-image-1"
            src={LocksReplacementImage1}
            alt="Картинка услуги"
          />
          <div>
            <p className="car-opening-paragraph">
              Необходимая замена замков входной двери в Москве – услуга, с
              которой хотя бы раз сталкивался владелец квартиры. Если нужно с
              выездом на дом установить взломостойкий механизм, то без помощи
              специалиста не обойтись. Как вызвать мастера? Мы принимаем заявки
              круглосуточно, знаем как комплексно, оперативно и недорого решить
              задачи.
            </p>
            <h3>Срочная замена замков входной двери квартиры</h3>
            <p className="car-opening-paragraph">
              Стоимость услуг в разных компаниях вызывает шок. У нас цены на
              смену замков входной металлической двери приемлемые и
              аргументированные. Мы устанавливаем лояльные расценки, хотим
              привлечь новых клиентов, а не отпугнуть их. Не имеем дел с
              посредниками, стоимость услуг радует прагматичных заказчиков, а
              входное полотно в квартиру преображается на глазах.
            </p>
            <p className="car-opening-paragraph">
              услуги, которые оказывает наша компания:
            </p>
            <ul>
              <li>Замена замков на входной двери</li>
              <li>Установка цилиндров</li>
              <li>Перекодировка ключей</li>
              <li>Врезка дополнительных задвижек</li>
              <li>Ремонт металлических дверей</li>
            </ul>
          </div>
        </div>
        <div className="square-container">
          <div>
            <h3>Замена декоративной панели на входной двери</h3>
            <p className="car-opening-paragraph">
              После диагностики запорной системы найдем причину или корень
              проблемы, ликвидируем ее, поменяем цилиндр или механизм, который
              не работает, был повреж
            </p>

            <p className="car-opening-paragraph">
              Хотите узнать, сколько стоит заменить замок входной металлической
              двери - диспетчер сразу озвучит стоимость по телефону. Основные
              цены указаны на сайте, эконом модель вместе с выездом мастера,
              работой под ключ составит от 1000 до 4000 рублей, в зависимости от
              производителя.
            </p>
            <p className="car-opening-paragraph">
              Все работы проводятся за один выезд мастера, замеры даже в день
              обращения. Специалисты работают в этой области более 10 лет, знают
              все тонкости работы. Выезжаем в удобное для вас время, грамотно
              осуществляем замену панели входной двери.
            </p>

            <p className="car-opening-paragraph">
              Мы знаем, что не всегда есть время ждать мастера, поэтому
              <span className="bold-text"> экономим Ваше время</span> и
              незамедлительно приезжаем к Вам
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={LocksReplacementImage2}
            alt="Картинка услуги"
          />
        </div>
      </section>

      {/* -------------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Установка замков на входную дверь</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Специалисты компании устанавливают замки на входные железные
              двери, включая бронированные, кодовые и секретные механизмы с
              гарантией. Работы выполняем по фиксированной цене, приезжаем на
              дом с новыми замками, ручками. Время выезда мастера по Москве
              менее часа, поэтому можно доверить срочное решение проблемы нашему
              профессионалу.
            </p>
            <p className="car-opening-paragraph">
              Специалистам службы не раз приходилось устанавливать замки во
              входные двери со срочным выездом на квартиру или частный дом.
              Монтажные работы проводятся тогда, когда нужно поставить
              дополнительный замок или задвижку. Однако чаще, необходимость
              вызова мастера возникает из-за интенсивной эксплуатации полотна:
            </p>
            <ul className="p-0">
              <li>износ старого механизма или заклинивание цилиндра;</li>
              <li>монтаж сомнительного качества, без гарантии;</li>
              <li>
                сложно установить замок на входную дверь вкладным способом или
                на клепки;
              </li>
              <li>попытки взлома или вскрытия квартиры;</li>
              <li>
                неграмотный выбор размера сменного секрета, цилиндра, ячейки.
              </li>
            </ul>
          </div>
          <img
            className="door-opening-image-1"
            src={LocksInstallingImage2}
            alt="Картинка услуги"
          />
        </div>

        <p className="car-opening-paragraph">
          Если механизм перестает работать, то необходимо действовать. Речь идет
          о безопасности, сохранности имущества. Для наших мастеров установка
          входных замков в Москве ежедневная работа, поэтому мы знаем все
          технические тонкости, недорого усилим взломостойкость замочной
          скважины, устраним проблему на месте.
        </p>
        <h4>
          Цены на установку замков на входную дверь от 1500 до 3000 рублей
        </h4>
        <p className="car-opening-paragraph p-0">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>

      {/* ----------------------------------------------- */}
      <section className="car-opening-content">
        <h2>Установка умных замков</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Задумались о гарантированной безопасности Вашего дома? Тогда
              smart-замок (умный замок) - это Ваш выбор! Установка электронных
              замков на дверь избавит Вас от необходимости постоянно носить с
              собой связку ключей, ведь "умные замки" можно открыть без
              использования ключа,электронные замки могут открываться с помощью:
            </p>

            <ul>
              <li>Ключа или брелка</li>
              <li>Телефона (из любой точки мира)</li>
              <li>Кода</li>
              <li>Отпечатка пальца</li>
              <li>Лица или сетчатки глаза</li>
            </ul>
            <p className="car-opening-paragraph">
              Наши мастера профессионально установят умный замок на Вашу дверь.
              Расскажут, как им пользоваться, а также предложат техническое
              сопровождение после установки. Цена на установку электронных
              замков от 8000 руб
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={SmartLock1}
            alt="Картинка услуги"
          />
        </div>
      </section>

      {/* -------------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Ремонт замков входной двери</h2>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={LockRepairingEntranceImage}
            alt="Картинка услуги"
          />
          <div>
            <p className="car-opening-paragraph">
              Заметили, что полотно закрывается не так, как прежде? Лучше сразу
              вызвать мастера, и поручить ремонт дверного замка на металлической
              двери грамотному специалисту.
            </p>
            <p className="car-opening-paragraph">
              Не стоит пытаться самостоятельно починить неисправный замок!
              Поручите ремонт замков входных дверей в Москве нам, и вам не
              придется переживать о своем выборе. Вовремя вызвать мастера на дом
              – верное решение возникшей проблемы
            </p>
          </div>
        </div>
        <div className="square-container">
          <div>
            <h3>
              Ремонт замков входной металлической двери за один выезд на дом
            </h3>
            <h4>Наши сотрудники могут выполнить:</h4>
            <ul>
              <li>Ремонт заклинивающего замка</li>
              <li>Перекодировку ключей</li>
              <li>Сварочные работы и замену петель</li>
              <li>Устранение перекоса</li>
              <li>Перекос конструкции</li>
              <li>Реставрацию после вскрытия МЧС</li>
            </ul>
            <h4>Звоните! Мы поможем вам!</h4>

            <p className="car-opening-paragraph">
              Мастер срочно приезжает на квартиру к заказчику, в каком бы районе
              Москвы он не находился.
            </p>
            <p className="car-opening-paragraph">
              Стоит отметить, мы выполняем полное обслуживание металлических,
              ремонт входных дверей с бесплатной диагностикой, восстановлением
              железных со сваркой после вскрытия МЧС на пороге квартиры
            </p>
            <h4>Стоимость ремонта зависит от обьема и сложности работ.</h4>
          </div>
          <img
            className="door-opening-image-1"
            src={Image1}
            alt="Картинка услуги"
          />
        </div>
      </section>

      {/* ----------------------------------------------------------- */}
      <section className="car-opening-content">
        <h2>Замена цилиндра замка</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Замена цилиндра или сердцевины замка входной двери в квартиру,
              если в ней сломался ключ, у новичка может вызвать массу вопросов.
              Где взять инструменты? Нужно ли полностью разбирать запорный
              механизм? Какой фирмы цилиндр, ячейка, сердечник подойдет по
              размерам? Не советуем брать на себя ответственность, если не
              разбираетесь в профильной работе. В Москве, гораздо выгоднее
              поручить замену грамотному мастеру. Специалист аккуратно
              демонтирует старый цилиндр из замка, модернизирует запорный
              механизм и установит новый элемент за один выезд.
            </p>

            <h4>Возможно вы столкнулись с одной из следующих проблем:</h4>
            <ul>
              <li>Цилиндр заклинило</li>
              <li>Потеря ключей от замка</li>
              <li>Переезд на новую квартиру</li>
              <li>Замена цилиндра замка после квартирантов</li>
            </ul>
            <h4>
              Звоните нам! Мы поможем решить вашу проблему быстро и качественно!
            </h4>
          </div>
          <img
            className="car-opening-image"
            src={CyliderImage1}
            alt="Картинка услуги"
          />
        </div>

        <h3>Бесплатный выезд мастеров для замены цилиндра замка</h3>
        <p className="car-opening-paragraph">
          Если вы потеряли ключи, вам не нужно приобретать новый дорогой
          механизм. Вы звоните в наш сервис и мастер выезжает на объект. Слесарь
          подъедет на вызов в удобное для клиента время с набором фурнитуры и
          инструментов. Конфиденциальность и порядочность со стороны мастера
          гарантирована, так как мы дорожим своей репутацией и доверием
          заказчиков.
        </p>

        <h4>Стоимость замены цилиндра замка начинается с 1500 рублей</h4>
        <p className="car-opening-paragraph">
          При необходимости специалист проконсультирует вас, подскажет какая
          модель лучше и почему. Мы готовы установить цилиндр с перфорированным
          и английским ключом, механизмы высокой степени секретности.
        </p>
      </section>

      {/* ----------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Установка броненаклаки на замок</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Установка брони или броненакладки на замок повышает уровень защиты
              входной двери, придает уверенность в ее неприступности для
              злоумышленников.
            </p>

            <p className="car-opening-paragraph">
              Броненакладка на замок - это шайба, отлитая из прочного
              закаленного сплава металлов. Она защищает корпус и цилиндр замка
              от силовых способов вскрытия:
            </p>
            <ul className="p-0">
              <li>выбивания</li>
              <li>высверливания</li>
              <li>излома</li>
              <li>вытягивания</li>
              <li>вырывания механизма</li>
            </ul>
            <p className="car-opening-paragraph">
              Бронированная накладка состоит из двух металлических пластин -
              внешней и внутренней, стянутых винтами, насквозь проходящими через
              дверное полотно и корпус замка. По способу установки броненакладки
              делятся на врезные и накладные модели.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={OverlaysImage1}
            alt="Картинка услуги"
          />
        </div>

        <h3>Как устанавливается броненакладка?</h3>
        <p className="car-opening-paragraph">
          Установить броненакладку можно на любую дверь, главное чтобы корпус
          замка предусматривал установочные отверстия для монтажа!
        </p>
        <p className="car-opening-paragraph">
          Монтаж врезной броненакладки требует высверливания круглого
          технологического отверстия внешнего листа металла. Проделанное
          отверстие по диаметру должно идеально совпадать с размером углубления
          внешней стороны брони. Самый сложный момент установки - не
          промахнуться с размером отверстия.
        </p>
        <p className="car-opening-paragraph">
          Главное, чтобы между бронированной накладкой и краем вырезанного
          дверного полотна не осталось зазора - это не позволит выдавить, выбить
          ее подручными средствами, сильно осложнит работу взломщика в попытке
          добраться до цилиндра замка.
        </p>
        <h4>
          Наши специалисты могут с ювелирной точностью установить броненакладку
          на вашу дверь!
        </h4>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
        <div>
          <Button style={btn_styled} className="btn-style" color="primary" variant="outlined" onClick={handleOpen}>
            <PhoneInTalkIcon fontSize={"large"} />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="modal-w">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Оставьте заявку и мы вам позвоним
              </Typography>
              <TextField id="standard-basic" label="Имя" variant="standard" />
              <TextField required id="standard-basic" label="Номер телефона" variant="standard" />
              <TextField id="standard-basic" label="Опишите вашу проблему" variant="standard" />
              <Button >Добавить фото</Button>
              <Button >Отправить</Button>
            </Box>
          </Modal>
        </div>
      </section>
    </main>
  );
};

export default LocksReplacementEntrance;
