import React from "react";
import "./style.scss";
import DoorRepairingEntranceImage from "../../../../assets/images/services/door-repairing1.png";
import DoorHandleImage2 from "../../../../assets/images/handle-1.jpeg";
import DoorHandleImage3 from "../../../../assets/images/handle-2.jpeg";
import DoorHingesImage from "../../../../assets/images/13-petli.png";
import DoorHingesImage1 from "../../../../assets/images/petli.jpeg";
import DoorHingesImage2 from "../../../../assets/images/addedimage2.png";
import DoorHingesImage3 from "../../../../assets/images/added2.png";
import { Button, Modal, Box, TextField, Typography } from "@material-ui/core";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
};
const btn_styled = {
  position: "fixed",
  top: "90%",
  left: "90%",
  background: "rgba(101, 66, 122, 0.9)",
  color: "white"
}

const DoorRepairingEntrance = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт дверей</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт входных дверей</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Часто люди думают, что ремонт входной двери или замену замка можно
              произвести самостостельно. Но ремонт входных дверей в квартире –
              сложный, многоступенчатый процесс, поэтому лучше вызвать опытного
              мастера со стажем. Какие неисправности самые частые?:
            </p>
            <h3>Возможно, вы столкнулись с одной из следующих проблем:</h3>
            <ul>
              <li>Износ фурнитуры, петель</li>
              <li>Порванная обивка</li>
              <li>Провисание, царапание о порог</li>
              <li>Перекос конструкции</li>
              <li>Повреждение щеколды, задвижки, дверной ручки</li>
              <li>Сломанные замки</li>
              <li>Рассыхание уплотнителей</li>
            </ul>
          </div>
          <img
            className="door-opening-image-1"
            src={DoorRepairingEntranceImage}
            alt="Картинка услуги"
          />
        </div>
        <h4>Звоните! Мы поможем вам!</h4>
        <p className="car-opening-paragraph">
          Мастер срочно приезжает на квартиру к заказчику, в каком бы районе
          Москвы он не находился.
        </p>
        <p className="car-opening-paragraph">
          Стоит отметить, мы выполняем полное обслуживание металлических, ремонт
          входных дверей с бесплатной диагностикой, восстановлением железных со
          сваркой после вскрытия МЧС на пороге квартиры
        </p>
      </section>

      {/* --------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Ремонт дверных ручек</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Поручите ремонт дверной ручки металлической двери в Москве
              мастерам, и выбросьте из головы все бытовые заботы. Наши
              специалисты отремонтировали крепления и переставили на стяжки
              сотни моделей замков, благодаря чему клиенты сэкономили немало
              денег на стоимости новой ручки.
            </p>
            <p className="car-opening-paragraph">
              Хотя сломанные ручки, как правило, меняются на новые, все же во
              многих случаях крепления можно починить. На уличные или двери в
              квартиру устанавливают стационарные и нажимные модели. Если первые
              используются для жесткого открытия металлической двери, то вторые
              выступают в роли защелки запорного механизма.
            </p>
            <p className="car-opening-paragraph">
              Наиболее частые проблемы клиентов:
            </p>
            <ul>
              <li>Выпадает дверная ручка.</li>
              <li>Вышел из строя внутренний квадрат.</li>
              <li>Не открывается защелка в результате износа.</li>
              <li>Ручка не возвращается в исходное положение.</li>
              <li>Повреждено стопорное кольцо</li>
              <li>Отлетел, сломался поворотник на входной двери.</li>
            </ul>
          </div>
          <img
            className="door-opening-image-1"
            src={DoorHandleImage2}
            alt="Картинка услуги"
          />
        </div>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={DoorHandleImage3}
            alt="Картинка услуги"
          />

          <div>
            <p className="car-opening-paragraph">
              На металлической двери ремонт ручки обязателен с момента
              возникновения проблем. Ведь в этом случае владелец квартиры
              рискует, стать легкой добычей для злоумышленников. Если вы
              обнаружили тревожные симптомы, не игнорируйте их в надежде, что
              функциональный элемент еще не сломан.
            </p>

            <p className="car-opening-paragraph">
              Открывать железную дверь квартиры со сломанной ручкой одно
              мучение. Лучше сразу отремонтировать ее, пока это еще возможно.
              Если затягивать с починкой, то придется менять ее на новую, а
              значит нести дополнительные финансовые потери.
            </p>
            <h4>Стоимость ремонта дверной ручки: 2000 рублей</h4>

            <p className="car-opening-paragraph">
              Мы знаем, что не всегда есть время ждать мастера, поэтому
              <span className="bold-text"> экономим Ваше время</span> и
              незамедлительно приезжаем к Вам
            </p>
          </div>
        </div>
      </section>

      {/* ---------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Ремонт петель</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Часто владельцы квартир просто не замечают повреждений петель. Их
              видимые дефекты не так очевидны, как сломанный замок или дверная
              ручка.
            </p>
            <p className="car-opening-paragraph">
              Предлагаем качественный ремонт петель на месте с выездом в день
              обращения по Москве и области. Достаточно вызвать мастера на дом,
              а специалист выполнит работу профессионально, аккуратно, по
              аргументированным ценам.
            </p>
            <h3>Ремонт петель входной двери</h3>
            <p className="car-opening-paragraph">
              Перекос или отрыв петли - довольно частая проблема, с которой наши
              мастера связываются постоянно.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={DoorHingesImage}
            alt="Картинка услуги"
          />
        </div>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={DoorHingesImage1}
            alt="Картинка услуги"
          />
          <div>
            <p className="car-opening-paragraph">
              Если вы обнаружили, что дверь стала провисать, с трудом
              закрываться, то, скорее всего, необходимо переварить петлю или
              осуществить ее ремонт. От качества и монтажа этих элементов и
              дверной фурнитуры зависит длительность эксплуатации, надежность
              самого полотна. В редких случаях, возможно, нужно будет заменить
              петлю.
            </p>
            <h4>Наша компания может помочь вам осуществить:</h4>
            <ul>
              <li>Замена петель на входных дверях</li>
              <li>Регулировка дверей</li>
              <li>Ремонт петли металлической двери</li>
              <li>
                Осуществление сварочных работ, грамотно приварить или переварить
                петлю железной двери;
              </li>
            </ul>

            <p className="car-opening-paragraph">
              Если вы заметили, что петля скрипит, не работает или вовсе
              отвалилась, не игнорируйте проблему! Мастерам службы под силу
              ликвидировать повреждение быстро и профессионально
            </p>
            <h4>Стоимость ремонта зависит от обьема и сложности работ</h4>
          </div>
        </div>
      </section>

      {/* --------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Регулировка дверей</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Когда входная дверь скрипит, провисает или туго закрывается,
              неудобство — не единственная проблема. Из-за дефектов конструкция
              пропускает сквозняки в дом, замки и петли быстрее изнашиваются.
              Когда нужна регулировка входной двери?
            </p>
            <p className="car-opening-paragraph">
              О некорректной работе входной двери можно судить по ряду
              характерных признаков, указывающих на возникновение дефекта :
              характерные скрипы; сложности при попытке закрыть / открыть замок,
              нажать на дверную ручку; сложности при закрытии / открытии двери;
              неплотная посадка дверного полотна в короб. Первое время
              определенная неисправность может быть незначительной и не
              доставлять особых неудобств. Но игнорирование таких проблем может
              привести к серьезным последствиям: замок может заклинить; появится
              сквозняк; дверное полотно будет промерзать; усилится шум с
              подъезда.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={DoorHingesImage2}
            alt="Картинка услуги"
          />
        </div>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={DoorHingesImage3}
            alt="Картинка услуги"
          />
          <div>
            <h4>
              Наши мастера выполняют регулировку различных дверных элементов,
              таких как:
            </h4>
            <ul>
              <li>дверной замок</li>
              <li>дверные петли</li>
              <li>дверные ручки</li>
              <li>бронекладки и другие</li>
            </ul>
            <p className="car-opening-paragraph">
              Осуществляем регулировку входных дверей, межкомнатных дверей и
              других видов.
            </p>

            <h4>Стоимость ремонта зависит от обьема и сложности работ</h4>
          </div>
        </div>
        <div>
          <Button style={btn_styled} className="btn-style" color="primary" variant="outlined" onClick={handleOpen}>
            <PhoneInTalkIcon fontSize={"large"} />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="modal-w">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Оставьте заявку и мы вам позвоним
              </Typography>
              <TextField id="standard-basic" label="Имя" variant="standard" />
              <TextField required id="standard-basic" label="Номер телефона" variant="standard" />
              <TextField id="standard-basic" label="Опишите вашу проблему" variant="standard" />
              <Button >Добавить фото</Button>
              <Button >Отправить</Button>
            </Box>
          </Modal>
        </div>
      </section>
    </main>
  );
};

export default DoorRepairingEntrance;
