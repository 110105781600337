import React from "react";
import "./style.scss";
import DoorOpeningImage1 from "../../../../assets/images/opening1.jpeg";
import DoorOpeningImage2 from "../../../../assets/images/opening2.jpeg";
import GarageOpeningImage1 from "../../../../assets/images/garage1.jpg";
import LatchOpeningImage1 from "../../../../assets/images/latch-1.png";
import CarOpeningImage1 from "../../../../assets/images/car-1.jpeg";
import CarOpeningImage2 from "../../../../assets/images/cars-1.jpeg";
import SafeOpeningImage1 from "../../../../assets/images/services/safe-opening.png";
import SafeOpeningImage2 from "../../../../assets/images/safe-1.png";
import Brands from "../../../../assets/images/brands.png";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import { Button, Modal, Box, TextField, Typography } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
};
const btn_styled = {
  position: "fixed",
  top: "90%",
  left: "90%",
  background: "rgba(101, 66, 122, 0.9)",
  color: "white"
}

const DoorOpening = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <main className="door-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Вскрытие замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Не можете открыть дверь жилого помещения, склада или офиса?</h2>
        <div class="square-container">
          <img
            className="door-opening-image-1"
            src={DoorOpeningImage1}
            alt="Картинка услуги"
          />

          <div>
            <p className="door-opening-paragraph ">
              Срочное вскрытие замков любой сложности без повреждений двери по
              доступной цене:
            </p>
            <ul className="pt-20">
              <li>Вскрытие отечественных замков от 1500р</li>
              <li>Вскрытие импортных замков от 3000р</li>
              <li>Вскрытие дверных задвижек от 2000р</li>
              <li>Почтовые и цилиндровые замки от 1000р</li>
              <li>Гаражные и сувальдные замки от 2000р</li>
              <li>Замков в входной двери с цепочкой или без от 1200 р.</li>
              <li>Замков электромеханического типа от 1800 р.</li>
            </ul>

            <p className="door-opening-paragraph">
              Вам может понадобиться помощь, если:
            </p>
            <ul>
              <li>ключи остались в квартире;</li>
              <li>в замочную скважину попал инородный предмет;</li>
              <li>ключи потерялись или были украдены;</li>
              <li>замок вышел из строя</li>
            </ul>

            <p className="door-opening-paragraph">
              Во всех описанных обстоятельствах лучше обратиться в нашу службу,
              а не пытаться справиться с проблемой самостоятельно.
            </p>
          </div>
        </div>
        <div className="square-container">
          <div>
            <h3>Почему мы?</h3>
            <ul>
              <li>
                Вскрываем двери в Москве и московский области. Квалифицированные
                мастера находятся в каждом районе города
              </li>
              <li>
                Принимаем заказы круглосуточно без выходных и праздничных дней.
                Наш эксперт приедет по вызову даже 31 декабря под бой курантов.
              </li>
              <li>
                Приступаем к обязанностям максимально быстро. Специалист будет у
                вашего порога в течение 20—30 мин.
              </li>
              <li>
                Знаем, как открыть замок любого типа. Нам подвластны сувальдные,
                цилиндровые, кодовые и другие механизмы.
              </li>
              <li>
                Работаем без повреждений. В 100% случаев дверь остаётся без
                вмятин, царапин и сколов.
              </li>
            </ul>

            <h4>Стоимость вскрытия дверного замка от 1500 руб</h4>
            <p className="door-opening-paragraph">
              Звоните по <a href="tel:+79687110240">+7(968)711-02-40</a> и наш
              мастер незамедлительно выедет в назначенное место.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={DoorOpeningImage2}
            alt="Картинка услуги"
          />
        </div>
      </section>
      {/* ------------------------------------------------------ */}

      <section className="car-opening-content">
        <h2>Аварийное вскрытие гаража</h2>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={GarageOpeningImage1}
            alt="Картинка услуги"
          />
          <div>
            <p className="car-opening-paragraph">
              Частая ситуация - ломается гаражный замок, но мы продолжаем его
              использовать. В конце концов, после поломки открыть гараж уже не
              возможно. А машину нужно иметь под рукой сейчас, необходимо ехать
              по делам. Что же в результате делать?
            </p>
            <p className="car-opening-paragraph">
              Лучше сразу вызвать мастера для вскрытия замков гаража. Наши
              специалисты могут срочно открыть механизм без ключа любого типа
              запирания. Вам необязательно резать петли болгаркой или пытаться
              ломать конструкцию, а потом дополнительно оплачивать ремонт – мы
              сделаем работу без повреждения, быстро и качественно.
            </p>
          </div>
        </div>
      </section>

      {/* ------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Аварийное вскрытие щеколды или защёлки</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Закрылась задвижка железной двери изнутри и не знаете куда
              звонить? Наша компания предлагает Вам рациональное решение!
            </p>
            <p className="car-opening-paragraph">
              Прежде чем портить дверь и пытаться отрыть щеколду или задвижку
              снаружи, лучше позвонить нашим мастерам. Это позволит избежать
              дополнительных затрат. Без практических навыков невозможно
              осуществить вскрытие щеколды на металлической входной двери
              успешно, быстро и грамотно.
            </p>
            <p className="car-opening-paragraph">
              Оградите себя от ряда беспокойств и проблем, вызовите мастера
              сервисной службы
              {/* сервисной службы, поскольку он: */}
            </p>
            <h3>
              Стоимость вскрытия щеколды или ночной задвижки от 900 рублей
            </h3>

            {/* <ul>
              <li>быстро приедет на объект</li>
              <li>привезет все инструменты с собой</li>
              <li>знает способы открытия без повреждения</li>
              <li>срочно откроет замок без ключа</li>
              <li>окажет мобильную помощь, проконсультирует</li>
              <li>выполнит работы за доступную цену</li>
            </ul>
            <p className="car-opening-paragraph">
              Мы знаем, что не всегда есть время ждать мастера, поэтому
              <span className="bold-text"> экономим Ваше время</span> и
              незамедлительно приезжаем к Вам
            </p> */}
          </div>

          <img
            className="door-opening-image-1"
            src={LatchOpeningImage1}
            alt="Картинка услуги"
          />
        </div>
      </section>

      {/* ------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Не можете попасть в собственное транспортное средство?</h2>

        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Пытаетесь открыть замок при помощи подручных инструментов?
            </p>
            <p className="car-opening-paragraph">
              Не рекомендуем решать данную проблему самостоятельно. Это может
              привести к дефектам лакокрасочного покрытия, выходу из строя
              электроники или повреждению замочной скважины. Обращайтесь в нашу
              службу! Квалифицированные мастера помогут вам вскрыть дверь
              автомобиля при любых обстоятельствах:
            </p>
            <ul>
              <li>неисправность замка</li>
              <li>потеря ключа</li>
              <li>поломка сигнализации</li>
              <li>блокировка средства передвижения с ключами внутри</li>
              <li>обледенение и промерзание дверного механизма</li>
            </ul>

            <p className="car-opening-paragraph">
              Услуга по вскрытию двери транспортного средства является законной
              и безопасной. Наш мастер окажет вам помощь при наличии документов,
              подтверждающих право собственности.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={CarOpeningImage1}
            alt="Картинка услуги"
          />
        </div>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={Brands}
            alt="Картинка услуги"
          />
          <div>
            <p className="car-opening-paragraph">
              Автомобильные технологии не стоят на месте, автопроизводители
              выпускают современные модели со встроенной двойной блокировкой.
              Если разбить боковое стекло, то вскрыть машину не получится –
              электронная система защиты заблокирует внутренние ручки двери.
              Современный профессиональный инструмент в арсенале наших мастеров,
              позволяет производить вскрытие без повреждения.
            </p>
            <h3>Сколько стоит вскрытие автомобилей без ключа?</h3>
            <ul>
              <li>Отечественные автомобили от 1500 руб</li>
              <li>Открыть иномарку без ключа от 2000 руб</li>
              <li>Бизнес-класс от 3000 руб</li>
            </ul>
            <p className="car-opening-paragraph">
              Звоните по номеру <a href="tel:+79687110240">+7(968)711-02-40</a>{" "}
              и мы быстро прибудем к месту расположения автомобиля.
            </p>
          </div>
        </div>
      </section>

      {/* ------------------------------------------------------- */}

      <section className="car-opening-content">
        <h2>Срочное вскрытие сейфа услуги любой сложности</h2>
        <div className="square-container">
          <div>
            <p className="car-opening-paragraph">
              Сейфы используются как в жилых, так и в коммерческих помещениях
              для хранения денег, ценных вещей и бумаг.
            </p>
            <p className="car-opening-paragraph">
              Чаще всего выполнены из металла, имеют прочную конструкцию. Могут
              быть оснащены кодовыми и механическими замками с большим числом
              секретных комбинаций. Однако иногда запирающие устройства начинают
              работать против владельцев, и требуется помощь специалистов по
              вскрытию сейфов. Современные модели имеют высокий уровень защиты
              от интеллектуального и силового взлома, поэтому вскрыть сейф
              самостоятельно очень сложно.
            </p>
          </div>
          <img
            className="door-opening-image-1"
            src={SafeOpeningImage1}
            alt="Картинка услуги"
          />
        </div>
        <div className="square-container">
          <img
            className="door-opening-image-1"
            src={SafeOpeningImage2}
            alt="Картинка услуги"
          />
          <div>
            <h3>
              Наша компания предоставляет услуги по вскрытию сейфов. Наши
              сотрудники имеют специальные инструменты, позволяющие оперативно
              открыть замок.
            </h3>
            <p className="car-opening-paragraph">
              Профессионалы затрачивают минимум времени, позволяют клиентам
              избавиться от стресса, забот. Инструменты и оборудование дают
              возможность мастеру открыть без ключа сейф{" "}
              <span className="bold-text">любой сложности</span>. Исключается
              необходимость замены или ремонта. Мастер гарантирует сохранность
              конструкции.
            </p>
          </div>
        </div>
        <p className="car-opening-paragraph">
          Инструменты и оборудование дают возможность мастеру открыть без ключа
          сейф любой сложности. Исключается необходимость замены или ремонта.
          Мастер гарантирует сохранность конструкции Высокая квалификация
          позволяет предлагать вам услуги:
        </p>
        <ul>
          <li>открытие сейфов без повреждения;</li>
          <li>перекодирование ключей;</li>
          <li>ремонт и восстановление;</li>
          <li>смена паролей;</li>
          <li>разблокировка защиты;</li>
          <li>замена цилиндров замка.</li>
          <li>диагностика механизмов;</li>
        </ul>
        <p className="car-opening-paragraph">
          Наши сотрудники сделают все, чтобы подтвердить статус компании
          высокого уровня обслуживания.
        </p>
        <h3>Вскрытие замка сейфа: когда нужна такая услуга?</h3>
        <ul>
          <li>Утеряны ключи или забыт код;</li>
          <li>
            Произошел сбой в работе запирающего механизма и он функционирует
            неисправно;
          </li>
          <li>Замок засорился, в него попал мелкий мусор или пыль;</li>
          <li>
            Аварийные вскрытие сейфов потребуется и в случае, если ключ сломался
            пополам во время закрывания/ открывания, его часть осталась внутри
            замочной скважины;
          </li>
        </ul>
        <h3>Процесс вскрытия сейфа:</h3>
        <p className="car-opening-paragraph">
          Вызов мастера экстренной службы – то, что нужно, если стремитесь
          свести риск повреждения хранилища к минимуму, сохранить
          конфиденциальность. Наши сотрудники к каждому заказу подходят
          индивидуально, не в свете набранных работ.
        </p>
        <p className="car-opening-paragraph">
          Если необходимо срочно вскрыть сейф без повреждений, мы приедем на
          дом, быстро устраним проблему. Наша цель свести любые неисправности к
          нулю независимо от того, с какими моделями приходится работать,
          механическими или электронными. Если хранилище уже повреждено, мы
          восстановим работоспособность.И неважно, что случилось с механизмом:
          сломался ключ, утрачена секретная комбинация или заклинил замок.
        </p>

        <h3>Сколько стоит вскрытие сейфа в Москве?</h3>
        <p className="car-opening-paragraph">
          Стоимость зависит от вида запирающего механизма, от его уровня
          взломостойкости, от вида поломки. От этих параметров зависит и время,
          которое будет затрачено мастером на открытие. Не знаете, как вскрыть
          домашний сейф срочно? Звоните или оставляйте заявку на сайте !
        </p>
        <div>
          <Button style={btn_styled} className="btn-style" color="primary" variant="outlined" onClick={handleOpen}>
            <PhoneInTalkIcon fontSize={"large"} />

          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="modal-w">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Оставьте заявку и мы вам позвоним
              </Typography>
              <TextField id="standard-basic" label="Имя" variant="standard" />
              <TextField required id="standard-basic" label="Номер телефона" variant="standard" />
              <TextField id="standard-basic" label="Опишите вашу проблему" variant="standard" />
              <Button >Добавить фото</Button>
              <Button >Отправить</Button>
            </Box>
          </Modal>
        </div>
      </section>
    </main>
  );
};

export default DoorOpening;
