import React from "react";
import "./style.scss";
import CisaInstallBefore from "../../assets/images/works/cisa-install-before.jpg";
import CisaInstallAfter from "../../assets/images/works/cisa-install3-after.jpg";
import Lock1Before from "../../assets/images/works/lock1-before.jpg";
import Lock1After from "../../assets/images/works/lock1-after.jpg";
import Lock2Before from "../../assets/images/works/lock2-before.jpg";
import Lock2After from "../../assets/images/works/lock2-after.jpg";
import Cylinder1Before from "../../assets/images/works/cylinder1-before.jpg";
import Cylinder1After from "../../assets/images/works/cylinder1-after.jpg";
import Lock3Before from "../../assets/images/works/lock3-before.jpg";
import Lock3After from "../../assets/images/works/lock3-after.jpg";

import { Link } from "react-router-dom";
import { Button, Modal, Box, TextField, Typography } from "@material-ui/core";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
};
const btn_styled = {
  position: "fixed",
  top: "90%",
  left: "90%",
  background: "rgba(101, 66, 122, 0.9)",
  color: "white"
}

const OurWorksPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <main className="portfolio">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Наши работы</h1>
        </div>
      </section>
      <section className="works">
        <h2 className="works-title">Примеры выполненных нами работ</h2>
        <div className="works-item">
          <div className="works-item-description">
            <div className="works-item-images">
              <img src={CisaInstallBefore} alt="Замена замка" />
              <img src={CisaInstallAfter} alt="Замена замка" />
            </div>
            <div className="works-item-text">
              <h3 className="works-item-title">Замена замка и ручки</h3>
              <p>
                Нашими мастерами была произведена установка замка и дверной
                ручки фирмы Cisa.
              </p>
              <p>
                Подробнее о данной услуге вы можете почитать списке наших{" "}
                <Link to="/services">Услуг</Link>
              </p>
              <p>
                Подробнее о замках и ручках Cisa вы можете прочитать в нашем{" "}
                <Link to="/catalog">Каталоге</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="works-item">
          <div className="works-item-description">
            <div className="works-item-images">
              <img src={Lock1Before} alt="Замена замка" />
              <img src={Lock1After} alt="Замена замка" />
            </div>
            <div className="works-item-text">
              <h3 className="works-item-title">Замена накладного замка</h3>
              <p>
                Нашими мастерами была произведена замена накладного замка на
                воротах частного дома.
              </p>
              <p>
                Подробнее о данной услуге вы можете почитать списке наших{" "}
                <Link to="/services">Услуг</Link>
              </p>
              <p>
                Подробнее о замках вы можете прочитать в нашем{" "}
                <Link to="/catalog">Каталоге</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="works-item">
          <div className="works-item-description">
            <div className="works-item-images">
              <img src={Lock2Before} alt="Замена замка" />
              <img src={Lock2After} alt="Замена замка" />
            </div>
            <div className="works-item-text">
              <h3 className="works-item-title">Замена дверного замка</h3>
              <p>
                Нашими мастерами была произведена работа по замене дверного
                замка.
              </p>
              <p>
                Подробнее о данной услуге вы можете почитать списке наших{" "}
                <Link to="/services">Услуг</Link>
              </p>
              <p>
                Подробнее о замках вы можете прочитать в нашем{" "}
                <Link to="/catalog">Каталоге</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="works-item">
          <div className="works-item-description">
            <div className="works-item-images">
              <img src={Cylinder1Before} alt="Замена цилиндра" />
              <img src={Cylinder1After} alt="Замена цилиндра" />
            </div>
            <div className="works-item-text">
              <h3 className="works-item-title">
                Замена цилиндра и броненакладки
              </h3>
              <p>
                Нашими мастерами была произведена работа по замене цилиндра
                замка, а так же броненакладки на замок.
              </p>
              <p>
                Подробнее о данной услуге вы можете почитать списке наших{" "}
                <Link to="/services">Услуг</Link>
              </p>
              <p>
                Подробнее о замках и броненакладках вы можете прочитать в нашем{" "}
                <Link to="/catalog">Каталоге</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="works-item">
          <div className="works-item-description">
            <div className="works-item-images">
              <img src={Lock3Before} alt="Замена замка" />
              <img src={Lock3After} alt="Замена замка" />
            </div>
            <div className="works-item-text">
              <h3 className="works-item-title">
                Уснановка кодового замка Меттэм
              </h3>
              <p>
                Нашими мастерами была произведена установка кодового замка фирмы
                Меттэм.
              </p>
              <p>
                Подробнее о данной услуге вы можете почитать списке наших{" "}
                <Link to="/services">Услуг</Link>
              </p>
              <p>
                Подробнее о замках вы можете прочитать в нашем{" "}
                <Link to="/catalog">Каталоге</Link>
              </p>
            </div>
          </div>
        </div>
        <div>
          <Button style={btn_styled} className="btn-style" color="primary" variant="outlined" onClick={handleOpen}>
            <PhoneInTalkIcon fontSize={"large"} />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="modal-w">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Оставьте заявку и мы вам позвоним
              </Typography>
              <TextField id="standard-basic" label="Имя" variant="standard" />
              <TextField required id="standard-basic" label="Номер телефона" variant="standard" />
              <TextField id="standard-basic" label="Опишите вашу проблему" variant="standard" />
              <Button >Добавить фото</Button>
              <Button >Отправить</Button>
            </Box>
          </Modal>
        </div>
      </section>
    </main>
  );
};

export default OurWorksPage;
